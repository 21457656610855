html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif !important;
  overflow-x: hidden;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Nunito", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.btn {
  border-radius: 0;
  border: 1px solid white;
  color: white !important;
  height: 42px;
  line-height: normal;
}

.br-0 {
  border-right: 0 !important;
}

.text-black {
  color: black !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.btn-dark {
  background-color: black;
}
.z-1 {
  z-index: 1;
}
.overflow-x-hidden {
  overflow: hidden;
}
.banner-overlay:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.contactBanner .btn {
  display: none;
}
.contactBanner .banner-text,
.contactBanner h1 {
  color: #fff !important;
}

.form-control-width-40 {
  width: 40%;
  margin: 0 auto;
}

.input-font-size {
  font-size: 13px !important;
  min-height: 40px !important ;
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
}
.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-font-size::placeholder,
.input-font-size::-ms-input-placeholder {
  color: #cacaca;
}

.input-font-color {
  color: #cacaca;
}
.color-cacaca {
  color: #cacaca;
}
#header-wrap {
  z-index: 11;
}
@media (max-width: 480px) {
  #header {
    margin-bottom: 40px;
  }
  #header-wrap {
    position: fixed !important;
  }
}

.menu-mobile-container {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 0 30px;
  z-index: 10;
}

header .menu-mobile-container {
  justify-content: center !important;
}

@media (max-width: 767px) {
  .menu-mobile-container {
    padding-bottom: 60px;
  }
}

.menu-mobile-container ul {
  list-style: none;
}

.menu-mobile-container ul li {
  text-align: center;
}
.close-mobile-menu {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 1;
}
.close-mobile-menu a {
  font-size: 20px;
  color: #444;
}

.space-detail-carousel.react-multi-carousel-list,
.home-galleries-carousel.react-multi-carousel-list {
  position: initial;
}
.react-multiple-carousel__arrow {
  background: #000000;
}
.home-galleries-carousel .react-multiple-carousel__arrow--right {
  right: calc(0%);
  top: 80px;
}
.home-galleries-carousel .react-multiple-carousel__arrow--left {
  left: calc(1%);
  top: 80px;
}
@media (max-width: 480px) {
  .home-galleries-carousel .react-multiple-carousel__arrow--right {
    right: calc(10%);
  }
  .home-galleries-carousel .react-multiple-carousel__arrow--left {
    left: calc(10%);
  }
}
.space-detail-carousel .react-multiple-carousel__arrow--right {
  right: calc(0%);
  top: 100px;
}
.space-detail-carousel .react-multiple-carousel__arrow--left {
  left: calc(0%);
  top: 100px;
}
.space-detail-carousel {
}
.space-detail-carousel .like-cards {
  min-height: 200px;
  width: 100%;
}

@media (max-width: 1199px) {
  .space-detail-carousel .like-cards {
    min-height: 300px;
  }
}
@media (min-width: 1200px) {
  .space-detail-carousel .react-multi-carousel-item {
    padding-right: 20px;
  }
  .space-detail-carousel .react-multi-carousel-item:nth-child(2n + 1) {
    min-width: 280px !important;
  }
  .space-detail-carousel .react-multi-carousel-item:nth-child(2n) {
    min-width: 200px !important;
    max-width: 200px !important;
    margin: 0 auto;
  }
}

.m0a {
  margin: 0 auto;
}

.fs-09em {
  font-size: 0.9em;
}

.btn-outline-dark {
  background-color: #fff;
  border: 2px solid #343a40 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.btn-outline-dark:hover {
  background-color: #fff !important;
  color: #343a40 !important;
  border: 2px solid #343a40 !important;
}

.btn-outline-secondary:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: initial;
}

.non-border-bottom {
  border-bottom: none;
}

.h-3 {
  height: 3px;
}

.menu-link:hover {
  color: black !important;
}

@media screen and (max-width: 999px) {
  #logo {
    justify-content: start !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-secondary-light {
  padding: 0 30px;
  background-color: #f6f6f6;
  color: black !important;
  cursor: pointer;
}

.tt-u {
  text-transform: uppercase;
}

.input-font-size::placeholder {
  font-size: 14px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.24);
}

.modal-dialog {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 530px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .modal-dialog {
    margin: 0 0.5rem;
  }
}

.modal {
  margin-top: 0;
  z-index: 9999;
}

.modal-backdrop {
  z-index: 9999;
}

.modal-content {
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-body {
  padding: 0 !important;
}

.active {
  transition: opacity 1s ease-out;
  opacity: 1;
  display: block;
}

.contactBanner p,
.contactBanner u {
  font-family: "Lora", serif !important;
}

.contactBanner {
  padding-left: 5px;
}

.contactBlack {
  color: #121212 !important;
}

.noResults {
  text-align: center;
  padding-top: 50px;
  font-size: 24px;
  color: gray;
  margin-bottom: 0;
}

.btn > .material-symbols-outlined {
  margin-top: -2px;
}

.modal-header .close {
  z-index: 99;
}

.artplacer-button.btn {
  line-height: 42px;
  min-width: 178px;
}

@media screen and (max-width: 767px) {
  .artplacer-button.btn {
    width: 100% !important;
  }
}

/* Google ReCaptcha */
.grecaptcha-badge {
  display: none !important;
}
