.footer-border {
  border-top: 1px solid #d9d9d9 !important;
  margin-top: 30px;
}
.background-white {
  background-color: white !important;
}

.icon {
  position: fixed;
  z-index: 9999;
  bottom: 40px;
  right: 24px;
  background: black;
  padding: 12px;
  border-radius: 50%;
  color: white !important;
  cursor: pointer;
  display: none;
  transition: opacity 3s ease-out;
  opacity: 0;
}

.footer-logo {
  background-image: url("/artplacer-logo-footer.png");
  background-position: center center;
  background-size: contain;
  width: 150px;
  height: 30px;
  background-repeat: no-repeat;
  margin: 0 auto;
}
